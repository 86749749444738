import React, { useState } from 'react'
import { Button, Input, Form, Divider } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { SaveOutlined, FileSyncOutlined } from '@ant-design/icons'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import { fetch } from '../fetch'
import { master } from '../config'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const TheChangePassword = () => {
  const modal = useSelector((state) => state.modalChange)
  const dispatch = useDispatch()

  const [btnLoading, setbtnLoading] = useState(false)

  const closeModal = () => {
    dispatch({ type: 'set', modalChange: false })
  }

  const setStateClose = () => {
    setbtnLoading(false)
  }

  const onFinish = async (values) => {
    setbtnLoading(true)
    const data = await fetch('post', '/users/change/password', {
      user_id: Cookies.get(master),
      pass_old: values.passwordOld,
      pass_new: values.confirm,
    })

    if (data.status === 'success') {
      Swal.fire({
        icon: 'success',
        title: 'รหัสผ่านถูกเปลี่ยนเรียบร้อย',
        confirmButtonText: 'ตกลง',
        showConfirmButton: false,
        timer: 1200,
      })
      closeModal()
    } else {
      Swal.fire({
        icon: 'error',
        title: 'รหัสผ่านเก่าไม่ถูกต้อง',
        text: 'โปรดลองใหม่อีกครั้ง',
        confirmButtonText: 'ตกลง',
      }).then(() => {
        setbtnLoading(false)
      })
    }

    setbtnLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={closeModal} backdrop={!btnLoading} onClosed={setStateClose}>
        <Form {...layout} name="changepass" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <ModalHeader toggle={closeModal}>
            {
              <div>
                <FileSyncOutlined style={{ display: 'inline-grid', marginRight: 8 }} />
                เปลี่ยนรหัสผ่าน
              </div>
            }
          </ModalHeader>
          <ModalBody>
            <Form.Item
              className="mb-0"
              name="passwordOld"
              label="รหัสผ่านเก่า"
              rules={[
                {
                  required: true,
                  message: 'โปรดระบุรหัสผ่านเก่า!',
                },
              ]}
              hasFeedback
            >
              <Input.Password autoComplete={`new-password`} />
            </Form.Item>
            <Divider className="mb-3 mt-3" />
            <Form.Item
              name="password"
              label="รหัสผ่านใหม่"
              rules={[
                {
                  required: true,
                  message: 'โปรดระบุรหัสผ่านใหม่!',
                },
              ]}
              hasFeedback
            >
              <Input.Password autoComplete={`new-password`} />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="ยืนยันรหัสผ่านใหม่"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'โปรดยืนยันรหัสผ่านใหม่!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject('รหัสผ่านไม่ตรงกัน!')
                  },
                }),
              ]}
            >
              <Input.Password autoComplete={`new-password`} />
            </Form.Item>
          </ModalBody>
          <ModalFooter>
            <Form.Item className="mb-0">
              <Button
                type="primary"
                htmlType="submit"
                className="mr-2"
                icon={<SaveOutlined style={{ display: 'inline-grid' }} />}
                loading={btnLoading}
              >
                บันทึก
              </Button>
            </Form.Item>
            <Button type="primary" danger onClick={closeModal}>
              ยกเลิก
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  )

  // return (
  //     <div>
  //         <Modal
  //             title={
  //                 <div>
  //                     <FileSyncOutlined
  //                         style={{ display: "inline-grid", marginRight: 8 }}
  //                     />
  //                     เปลี่ยนรหัสผ่าน
  //                 </div>
  //             }
  //             visible={modal}
  //             onCancel={closeModal}
  //             // confirmLoading={btnLoading}
  //             okText="บันทึก"
  //             cancelText="ยกเลิก"
  //             onOk={onFinish}
  //             cancelButtonProps={{ type: "primary", danger: true }}
  //             okButtonProps={{ style: { display: "inline-grid", icon: <SaveOutlined /> }, type: "primary" }}
  //             // afterClose={setStateClose}
  //             closable
  //         >
  //             <Form
  //                 {...layout}
  //                 name="changepass"
  //                 onFinish={onFinish}
  //                 onFinishFailed={onFinishFailed}
  //             >
  //                 <Form.Item
  //                     className='mb-0'
  //                     name="passwordOld"
  //                     label="รหัสผ่านเก่า"
  //                     rules={[
  //                         {
  //                             required: true,
  //                             message: "โปรดระบุรหัสผ่านเก่า!",
  //                         },
  //                     ]}
  //                     hasFeedback
  //                 >
  //                     <Input.Password autoComplete={`new-password`} />
  //                 </Form.Item>
  //                 <Divider className="mb-3 mt-3" />
  //                 <Form.Item
  //                     name="password"
  //                     label="รหัสผ่านใหม่"
  //                     rules={[
  //                         {
  //                             required: true,
  //                             message: "โปรดระบุรหัสผ่านใหม่!",
  //                         },
  //                     ]}
  //                     hasFeedback
  //                 >
  //                     <Input.Password autoComplete={`new-password`} />
  //                 </Form.Item>

  //                 <Form.Item
  //                     name="confirm"
  //                     label="ยืนยันรหัสผ่านใหม่"
  //                     dependencies={["password"]}
  //                     hasFeedback
  //                     rules={[
  //                         {
  //                             required: true,
  //                             message: "โปรดยืนยันรหัสผ่านใหม่!",
  //                         },
  //                         ({ getFieldValue }) => ({
  //                             validator(rule, value) {
  //                                 if (!value || getFieldValue("password") === value) {
  //                                     return Promise.resolve();
  //                                 }
  //                                 return Promise.reject(
  //                                     "รหัสผ่านไม่ตรงกัน!"
  //                                 );
  //                             },
  //                         }),
  //                     ]}
  //                 >
  //                     <Input.Password autoComplete={`new-password`} />
  //                 </Form.Item>
  //             </Form>
  //         </Modal>
  //     </div>
  // );
}

export default TheChangePassword
