import React, { useState, useEffect, useRef } from 'react'
import Typed from 'typed.js'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CCard, CCardBody, CCol, CContainer, CRow, CImg } from '@coreui/react'
import Cookies from 'js-cookie'
import { Form, Button, Row, Col } from 'antd'

import { FormFeedback, Input } from 'reactstrap'
import { LoadingOutlined } from '@ant-design/icons'
import { master, remove, token, version } from '../../../config'
import { fetchLogin, fetch } from '../../../fetch'
import Logo from '../../../assets/img/praksa_logo.png'

const Login = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { socketLogin } = useSelector((state) => state)

  const [Loading, setLoading] = useState(false)
  const [messageCkkID, setMessageCkkID] = useState(false)
  const [messageCkkPass, setMessageCkkPass] = useState(false)
  const [show, setShow] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [textMessageUsername, setTextMessageUsername] = useState('Username  is required')
  const [textMessagePass, setTextMessagePass] = useState('Password is required')
  const [textMessageLogin, setTextMessageLogin] = useState('')

  //ref
  const el = useRef(null)
  const inputRefId = React.useRef(null)
  const inputRefPass = React.useRef(null)

  useEffect(() => {
    if (socketLogin !== null) {
      socketLogin?.disconnect()
    }
  }, [])

  useEffect(() => {
    // console.log('befor username: ', username)
    // console.log('befor password: ', password)
    // eslint-disable-next-line no-unused-vars
    const typed = new Typed(el.current, {
      strings: ['เมืองอุตสาหกรรม นำเศรษฐกิจ ชีวิตมีคุณภาพ สร้างชาติด้วยการศึกษา'], // Strings to display
      startDelay: 300,
      typeSpeed: 120,
      backSpeed: 120,
      backDelay: 100,
      loop: true,
    })
    remove()
    // Destropying
    return () => {
      // <div className="font-face-gm">
      typed.destroy()
      // </div>
    }
  }, [])

  const onFinish = async () => {
    setLoading(true)

    const user = username.toUpperCase()
    const data = await fetchLogin({ user_id: user, password: password })

    // console.log("data login:",data)
    // console.log("data login2:",data.message)
    if (data === undefined) {
      return setLoading(false)
    }

    if (data.status === 'success') {
      // console.log('data', data)
      Cookies.set(master, user)
      Cookies.set(token, data.data.access_token)
      history.push('/')
    } else {
      setMessageCkkID(true)
      setTextMessageLogin(data.data.message)
      inputRefId.current.focus()
      setLoading(false)
    }

    setLoading(false)
  }

  return (
    <div>
      <div className="c-app c-default-layout flex-row align-items-center font-face-gm">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="5">
              <div className="pe-lg-3 mb-7">
                <CImg className="boxshadows" src={Logo} height={100} />
                <h2>ระบบบริหารจัดการ SmartCity ทต.แพรกษา</h2>
                <span style={{ color: '#F26F21' }}>
                  <span ref={el} style={{ fontSize: '15px', fontFamily: 'cursive' }}></span>
                </span>
              </div>
            </CCol>

            <CCol md="5">
              <CCard
                className="p-2"
                style={{
                  borderRadius: '10px',
                  backgroundColor: 'rgba(255,255,255,0.7)',
                }}
              >
                <CCardBody>
                  <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    // onFinish={onFinish2}
                  >
                    <h2>Login</h2>
                    <br />
                    <Row>
                      <Col span={24}>
                        <Input
                          className="test-input"
                          placeholder="username"
                          name="username"
                          bsSize="lg"
                          onChange={(e) => {
                            // console.log('=> ', e)
                            setMessageCkkID(false)
                            setUsername(e.target.value)
                          }}
                          autoComplete={'off'}
                          value={username}
                          invalid={messageCkkID}
                          innerRef={inputRefId}
                          autoFocus
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              if (username === '' || username === undefined) {
                                setTextMessageLogin('')
                                return setMessageCkkID(true)
                              }
                              setMessageCkkID(false)
                              inputRefPass.current.focus()
                            }
                          }}
                          style={{
                            borderRadius: '5px',
                            // textTransform: "uppercase",
                          }}
                        />
                        <FormFeedback style={{ fontSize: '14px' }}>
                          {textMessageLogin !== '' ? textMessageLogin : textMessageUsername}
                        </FormFeedback>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col span={24}>
                        <div>
                          <Input
                            className="test-input"
                            placeholder="password"
                            name="password"
                            bsSize="lg"
                            type={show === true ? 'text' : 'password'}
                            onChange={(e) => {
                              setMessageCkkPass(false)
                              setPassword(e.target.value)
                            }}
                            autoComplete={'off'}
                            value={password}
                            invalid={messageCkkPass}
                            innerRef={inputRefPass}
                            // autoFocus

                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                if (password === '' || password === undefined) {
                                  return setMessageCkkPass(true)
                                }
                                if (username.length !== 0 && password.length !== 0) {
                                  return onFinish()
                                }
                              }
                            }}
                            style={{
                              borderRadius: '5px',
                              // textTransform: "uppercase",
                            }}
                          />
                          <span
                            onClick={(e) => {
                              setShow(show === true ? false : true)
                            }}
                          >
                            {show === false ? (
                              <i className="fa-solid fa-eye-slash field-icon mr-32" />
                            ) : (
                              <i className="fa-solid fa-eye field-icon mr-3" />
                            )}
                          </span>
                          <FormFeedback style={{ fontSize: '14px' }}>
                            {textMessagePass}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <br />
                  <Row>
                    <Col span={24}>
                      <Button
                        className="mt-1"
                        style={{
                          background: '#F26F21',
                          borderColor: '#F26F21',
                          borderRadius: '5px',
                        }}
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                          if (username.length === 0 && password.length === 0) {
                            inputRefId.current.focus()
                            setMessageCkkID(true)
                            setMessageCkkPass(true)
                            return
                          }
                          if (username === '' || username === undefined) {
                            inputRefId.current.focus()
                            return setMessageCkkID(true)
                          }
                          if (password === '' || password === undefined) {
                            inputRefPass.current.focus()
                            return setMessageCkkPass(true)
                          }

                          onFinish()
                        }}
                        disabled={Loading}
                        block
                        size="large"
                      >
                        {Loading ? (
                          <div>
                            <LoadingOutlined />
                            {'Loading.....'}
                          </div>
                        ) : (
                          <div className="font-weight-bold">{'LOGIN'}</div>
                        )}
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <div style={{ textAlign: 'right', color: '#F26F21' }}>
                    <u>Version {version}</u>
                  </div>
                  <br />
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
  )
}

export default Login
