import React, { useEffect } from 'react'
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Avatar } from 'antd'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { remove } from '../config'

const TheHeaderDropdown = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const openModal = () => {
    dispatch({ type: 'set', modalChange: true })
  }

  const LogOut = () => {
    remove()
    history.push('/login')
  }

  return (
    <>
      <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
        <CDropdownToggle className="c-header-nav-link" caret={false}>
          <Avatar size={40} style={{ backgroundColor: '#1CCA2C' }}>
            {props.userID}
          </Avatar>
        </CDropdownToggle>

        <CDropdownMenu className="pt-0" placement="bottom-end">
          {props.admin ? (
            <CDropdownItem header tag="div" color="light" className="text-center">
              <strong>ข้อมูลระบบ</strong>
            </CDropdownItem>
          ) : null}
          {props.admin ? (
            <CDropdownItem onClick={() => history.push('/user')}>
              <i className="fa-solid fa-user mr-2" />
              ข้อมูลผู้ใช้งาน
            </CDropdownItem>
          ) : null}
          {props.admin ? (
            <CDropdownItem onClick={() => history.push('/file')}>
              <i className="fa-solid fa-book mr-2" />
              ข้อมูลระบบงาน
            </CDropdownItem>
          ) : null}
          <CDropdownItem header tag="div" color="light" className="text-center">
            <strong>ตั้งค่า</strong>
          </CDropdownItem>
          <CDropdownItem onClick={openModal}>
            <i className="fa-solid fa-key mr-2" />
            เปลี่ยนรหัสผ่าน
          </CDropdownItem>
          <CDropdownItem divider className="mb-0" />
          <CDropdownItem onClick={LogOut}>
            <i className="fa-solid fa-right-from-bracket mr-2" />
            ออกจากระบบ
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </>
  )
}

export default TheHeaderDropdown
