import React from 'react'
import { CFooter } from '@coreui/react'

const TheFooter = (props) => {
	const { userID, lastLogin, version } = props
	return (
		<CFooter fixed={false}>
			<div>
				<span style={{ fontWeight: "bold" }}>Login : </span>
				<span>{userID}</span> &nbsp;&nbsp;

				<span className="ml-1" style={{ fontWeight: "bold" }}>เข้าสู่ระบบล่าสุด : </span>
				<span >{lastLogin}</span>
			</div>
			<div className="mfs-auto">
				<span className="ml-1" style={{ fontWeight: "bold" }}> Ver. {version} </span> &nbsp;
				<span className="mr-1">Powered by ทต.แพรกษา &copy; 2023</span>
			</div>
		</CFooter>
	)
}

export default React.memo(TheFooter)
