import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CHeader, CToggler, CHeaderBrand, CHeaderNav, CBreadcrumbRouter, CImg } from '@coreui/react'

import TheHeaderDropdown from './TheHeaderDropdown'

import routes from '../routes'

const TheHeader = (props) => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    dispatch({ type: 'set', sidebarShow: val })
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    dispatch({ type: 'set', sidebarShow: val })
  }

  const { user_id, fullName, admin } = props

  // console.log("userID",userID,"fullName", fullName, "admin",admin)

  return (
    <CHeader>
      <CToggler inHeader className="ml-md-3 d-lg-none" onClick={toggleSidebarMobile} />

      <CToggler inHeader className="ml-3 d-md-down-none" onClick={toggleSidebar} />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        {/* <CImg className="c-avatar-img" src={'/img/praksa_logo.png'} height={38} /> */}
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3" routes={routes} />
      </CHeaderNav>

      <CHeaderNav className="px-3">
        <div style={{ fontWeight: 'bold' }}>{fullName}</div>
        <TheHeaderDropdown userID={user_id} admin={admin} />
      </CHeaderNav>
    </CHeader>
  )
}

export default TheHeader
