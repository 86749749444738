import { createStore } from 'redux'

const initialState = {
	sidebarShow: 'responsive',
	modalChange: false,
	socketLogin: null,
	userType: "U",
	customer: {},
	api_path: '',
	focusedID: ''
}

const changeState = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case 'set':
			return { ...state, ...rest }
		case 'resetFocus':
			return { ...state, focusedID: '' }
		default:
			return state
	}
}

const store = createStore(changeState)
export default store